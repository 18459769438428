export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"msapplication-TileColor","content":"#003fce"},{"name":"theme-color","content":"#ffffff"}],"link":[{"rel":"apple-touch-icon","href":"/apple-touch-icon.png","sizes":"180x180"},{"rel":"icon","href":"/favicon-32x32-light.png","sizes":"32x32","type":"image/png","media":"(prefers-color-scheme: light)"},{"rel":"icon","href":"/favicon-16x16-light.png","sizes":"16x16","type":"image/png","media":"(prefers-color-scheme: light)"},{"rel":"icon","href":"/favicon-32x32-dark.png","sizes":"32x32","type":"image/png","media":"(prefers-color-scheme: dark)"},{"rel":"icon","href":"/favicon-16x16-dark.png","sizes":"16x16","type":"image/png","media":"(prefers-color-scheme: dark)"},{"rel":"manifest","href":"/site.webmanifest"}],"style":[],"script":[{"children":"window.isDashboard = true;"},{"src":"https://www.bugherd.com/sidebarv2.js?apikey=bjojh9kabsze6xnaafdelg","async":true},{"src":"/newrelic.js"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000