import boxesData from './boxes.json';

const accountCancellationPossibleReasons = [
  {
    reason: 'unpaidInvoices',
  },
  {
    reason: 'activeSubscriptions',
    data: [
      {
        id: 'd7607c45-4171-46e1-8108-00ac1b56aaaa',
        status: 'active',
        type: 'Email',
        startedAt: '2024-09-25T14:36:37.000000Z',
        renewsAt: '2025-09-01T14:36:37.000000Z',
        terminatedAt: null,
        nextPossibleCancellationDate: null,
        products: [
          {
            id: 'ea98c784-1afb-4247-b32d-fd43460ef8ab',
            internalName: 'Mail Starter',
            displayName: 'Setup',
            type: 'plan',
          },
          {
            id: '23cfa2f6-3bf2-44a6-9b2b-9caa03425a69',
            internalName: 'Mail Starter',
            displayName: 'Mail Starter',
            type: 'product',
          },
        ],
        configuration: [],
        meta: {
          providerReference: 'BTcecEUPOfrUV2NfJ',
          reference: null,
          details: [
            {
              id: '12345',
              title: 'test@postfach.de',
            },
          ],
          customer: {
            id: 'b696111d-8f80-4457-9ea2-7b9fe011d0bd',
            internalCustomerId: '65001',
          },
        },
        payment: {
          interval: 1,
          type: 'year',
          method: 'bank_transfer',
          meta: {
            providerReference: null,
          },
        },
        currency: null,
      },
      {
        id: 'f0344743-165c-4ab5-9405-2b304f2ed1cc',
        status: 'active',
        type: 'Email',
        startedAt: '2024-09-25T14:36:19.000000Z',
        renewsAt: '2025-09-01T14:36:19.000000Z',
        terminatedAt: null,
        nextPossibleCancellationDate: null,
        products: [
          {
            id: 'ea98c784-1afb-4247-b32d-fd43460ef8ab',
            internalName: 'Mail Starter',
            displayName: 'Setup',
            type: 'plan',
          },
          {
            id: '23cfa2f6-3bf2-44a6-9b2b-9caa03425a69',
            internalName: 'Mail Starter',
            displayName: 'Mail Starter',
            type: 'product',
          },
        ],
        configuration: [],
        meta: {
          providerReference: 'BTcecEUPOfmgk2Nd1',
          reference: null,
          details: [
            {
              id: '12345',
              title: 'test@postfach.de',
            },
          ],
          customer: {
            id: 'b696111d-8f80-4457-9ea2-7b9fe011d0bd',
            internalCustomerId: '65001',
          },
        },
        payment: {
          interval: 1,
          type: 'year',
          method: 'bank_transfer',
          meta: {
            providerReference: null,
          },
        },
        currency: null,
      },
      {
        id: '1d4200fd-f576-450c-92e7-100482fd0af0',
        status: 'active',
        type: 'Box',
        startedAt: '2024-09-25T14:28:51.000000Z',
        renewsAt: '2025-09-01T14:28:50.000000Z',
        terminatedAt: null,
        nextPossibleCancellationDate: null,
        products: [
          {
            id: '4ad9374d-4d78-4bda-96b1-ac43d58d426a',
            internalName: 'Pro Standard',
            displayName: 'Setup',
            type: 'plan',
          },
          {
            id: '72cc1b32-9f95-4169-9989-43640428c628',
            internalName: 'Pro Standard',
            displayName: 'Pro',
            type: 'product',
          },
          {
            id: 'eb75fcf8-6595-4e65-b821-ba27774e7a65',
            internalName: 'Fully Managed Add-On',
            displayName: 'Fully Managed Add-On',
            type: 'product',
          },
          {
            id: '93abb7e0-ae59-4afa-95d9-6b09c281a505',
            internalName: null,
            displayName: null,
            type: 'priceadjustment',
          },
          {
            id: '17aea2e4-31d7-4c86-a900-a1996befaab6',
            internalName: 'Server Promotion - New Customer 30% for 12 months',
            displayName: null,
            type: 'promotionaldiscount',
          },
        ],
        configuration: [],
        meta: {
          providerReference: 'BTLyZDUPOdu5o2N9u',
          reference: null,
          details: [
            {
              id: '12345',
              title: 'test@postfach.de',
            },
          ],
          customer: {
            id: 'b696111d-8f80-4457-9ea2-7b9fe011d0bd',
            internalCustomerId: '65001',
          },
        },
        payment: {
          interval: 1,
          type: 'year',
          method: 'bank_transfer',
          meta: {
            providerReference: null,
          },
        },
        currency: null,
      },
      {
        id: 'd9b5bc7e-d49c-4088-b7a7-6ebba3b56beb',
        status: 'active',
        type: 'Box',
        startedAt: '2024-09-25T14:28:29.000000Z',
        renewsAt: '2025-09-01T14:28:28.000000Z',
        terminatedAt: null,
        nextPossibleCancellationDate: null,
        products: [
          {
            id: '4ad9374d-4d78-4bda-96b1-ac43d58d426a',
            internalName: 'Pro Standard',
            displayName: 'Setup',
            type: 'plan',
          },
          {
            id: '72cc1b32-9f95-4169-9989-43640428c628',
            internalName: 'Pro Standard',
            displayName: 'Pro',
            type: 'product',
          },
          {
            id: 'eb75fcf8-6595-4e65-b821-ba27774e7a65',
            internalName: 'Fully Managed Add-On',
            displayName: 'Fully Managed Add-On',
            type: 'product',
          },
          {
            id: '93abb7e0-ae59-4afa-95d9-6b09c281a505',
            internalName: null,
            displayName: null,
            type: 'priceadjustment',
          },
          {
            id: '17aea2e4-31d7-4c86-a900-a1996befaab6',
            internalName: 'Server Promotion - New Customer 30% for 12 months',
            displayName: null,
            type: 'promotionaldiscount',
          },
        ],
        configuration: [],
        meta: {
          providerReference: 'BTTwWaUPOdoNZ2Lwp',
          reference: null,
          details: [
            {
              id: '12345',
              title: 'test@postfach.de',
            },
          ],
          customer: {
            id: 'b696111d-8f80-4457-9ea2-7b9fe011d0bd',
            internalCustomerId: '65001',
          },
        },
        payment: {
          interval: 1,
          type: 'year',
          method: 'bank_transfer',
          meta: {
            providerReference: null,
          },
        },
        currency: null,
      },
      {
        id: '7a8e70f3-e565-4b4e-ba77-89eb11da8b18',
        status: 'active',
        type: 'Box',
        startedAt: '2024-09-25T14:27:31.000000Z',
        renewsAt: '2025-09-01T14:27:30.000000Z',
        terminatedAt: null,
        nextPossibleCancellationDate: null,
        products: [
          {
            id: '4ad9374d-4d78-4bda-96b1-ac43d58d426a',
            internalName: 'Pro Standard',
            displayName: 'Setup',
            type: 'plan',
          },
          {
            id: '72cc1b32-9f95-4169-9989-43640428c628',
            internalName: 'Pro Standard',
            displayName: 'Pro',
            type: 'product',
          },
          {
            id: 'eb75fcf8-6595-4e65-b821-ba27774e7a65',
            internalName: 'Fully Managed Add-On',
            displayName: 'Fully Managed Add-On',
            type: 'product',
          },
          {
            id: '93abb7e0-ae59-4afa-95d9-6b09c281a505',
            internalName: null,
            displayName: null,
            type: 'priceadjustment',
          },
          {
            id: '17aea2e4-31d7-4c86-a900-a1996befaab6',
            internalName: 'Server Promotion - New Customer 30% for 12 months',
            displayName: null,
            type: 'promotionaldiscount',
          },
        ],
        configuration: [],
        meta: {
          providerReference: 'BTTwWaUPOdZKE2LrS',
          reference: null,
          details: [
            {
              id: '12345',
              title: 'test@postfach.de',
            },
          ],
          customer: {
            id: 'b696111d-8f80-4457-9ea2-7b9fe011d0bd',
            internalCustomerId: '65001',
          },
        },
        payment: {
          interval: 1,
          type: 'year',
          method: 'bank_transfer',
          meta: {
            providerReference: null,
          },
        },
        currency: null,
      },
      {
        id: '756a32a5-9747-4748-9487-a00ae9e7d7e5',
        status: 'active',
        type: 'Box',
        startedAt: '2024-09-25T14:26:17.000000Z',
        renewsAt: '2025-09-01T14:26:16.000000Z',
        terminatedAt: null,
        nextPossibleCancellationDate: null,
        products: [
          {
            id: '4ad9374d-4d78-4bda-96b1-ac43d58d426a',
            internalName: 'Pro Standard',
            displayName: 'Setup',
            type: 'plan',
          },
          {
            id: '72cc1b32-9f95-4169-9989-43640428c628',
            internalName: 'Pro Standard',
            displayName: 'Pro',
            type: 'product',
          },
          {
            id: 'eb75fcf8-6595-4e65-b821-ba27774e7a65',
            internalName: 'Fully Managed Add-On',
            displayName: 'Fully Managed Add-On',
            type: 'product',
          },
          {
            id: '93abb7e0-ae59-4afa-95d9-6b09c281a505',
            internalName: null,
            displayName: null,
            type: 'priceadjustment',
          },
          {
            id: '17aea2e4-31d7-4c86-a900-a1996befaab6',
            internalName: 'Server Promotion - New Customer 30% for 12 months',
            displayName: null,
            type: 'promotionaldiscount',
          },
        ],
        configuration: [],
        meta: {
          providerReference: 'BTcecEUPOdFxf2Mf9',
          reference: null,
          details: [
            {
              id: '12345',
              title: 'test@postfach.de',
            },
          ],
          customer: {
            id: 'b696111d-8f80-4457-9ea2-7b9fe011d0bd',
            internalCustomerId: '65001',
          },
        },
        payment: {
          interval: 1,
          type: 'year',
          method: 'bank_transfer',
          meta: {
            providerReference: null,
          },
        },
        currency: null,
      },
      {
        id: '426bbf55-09dc-4a9c-8269-ccbe9b404e45',
        status: 'active',
        type: 'Box',
        startedAt: '2024-09-25T14:18:04.000000Z',
        renewsAt: '2024-12-01T15:18:03.000000Z',
        terminatedAt: null,
        nextPossibleCancellationDate: null,
        products: [
          {
            id: 'eba0c6aa-e219-4f98-b43f-6c6264ee336a',
            internalName: 'Starter Standard',
            displayName: 'Setup',
            type: 'plan',
          },
          {
            id: '255f3350-a185-4d6e-a1ba-33db1bcbfffe',
            internalName: 'Starter Standard',
            displayName: 'Starter',
            type: 'product',
          },
          {
            id: 'eb75fcf8-6595-4e65-b821-ba27774e7a65',
            internalName: 'Fully Managed Add-On',
            displayName: 'Fully Managed Add-On',
            type: 'product',
          },
        ],
        configuration: [],
        meta: {
          providerReference: 'BTcecEUPObBjZ2Lge',
          reference: null,
          details: [
            {
              id: '12345',
              title: 'test@postfach.de',
            },
          ],
          customer: {
            id: 'b696111d-8f80-4457-9ea2-7b9fe011d0bd',
            internalCustomerId: '65001',
          },
        },
        payment: {
          interval: 1,
          type: 'month',
          method: 'bank_transfer',
          meta: {
            providerReference: null,
          },
        },
        currency: null,
      },
      {
        id: '333cc2bf-549d-4def-ad4c-a0268a1f6281',
        status: 'active',
        type: 'Domain',
        startedAt: '2024-09-13T12:56:02.000000Z',
        renewsAt: '2025-10-01T12:57:20.000000Z',
        terminatedAt: null,
        nextPossibleCancellationDate: null,
        products: [
          {
            id: 'fae644be-d9a5-43dd-9f2c-817f7fd70d59',
            internalName: 'Domain .com',
            displayName: 'Setup',
            type: 'plan',
          },
          {
            id: '7f24f85b-cac3-417f-b30e-3643e1eeb4b9',
            internalName: 'Domain .com',
            displayName: 'Domain .com',
            type: 'product',
          },
        ],
        configuration: [],
        meta: {
          providerReference: '198OegUOG6YtkKmD',
          reference: null,
          details: [
            {
              title: 'second starter',
              id: '19778',
            },
          ],
          customer: {
            id: 'b696111d-8f80-4457-9ea2-7b9fe011d0bd',
            internalCustomerId: '65001',
          },
        },
        payment: {
          interval: 1,
          type: 'year',
          method: 'bank_transfer',
          meta: {
            providerReference: null,
          },
        },
        currency: null,
      },
      {
        id: 'a7b7229e-3802-4b2f-bfd0-d7e6b2631cdb',
        status: 'active',
        type: 'Email',
        startedAt: '2024-09-13T12:54:54.000000Z',
        renewsAt: '2024-12-01T13:54:54.000000Z',
        terminatedAt: null,
        nextPossibleCancellationDate: null,
        products: [
          {
            id: 'ea98c784-1afb-4247-b32d-fd43460ef8ab',
            internalName: 'Mail Starter',
            displayName: 'Setup',
            type: 'plan',
          },
          {
            id: '23cfa2f6-3bf2-44a6-9b2b-9caa03425a69',
            internalName: 'Mail Starter',
            displayName: 'Mail Starter',
            type: 'product',
          },
        ],
        configuration: [],
        meta: {
          providerReference: 'BTcejgUOG5wfdJT7',
          reference: null,
          details: [
            {
              title: 'second starter',
              id: '19778',
            },
          ],
          customer: {
            id: 'b696111d-8f80-4457-9ea2-7b9fe011d0bd',
            internalCustomerId: '65001',
          },
        },
        payment: {
          interval: 3,
          type: 'month',
          method: 'bank_transfer',
          meta: {
            providerReference: null,
          },
        },
        currency: null,
      },
      {
        id: '3bacc7c0-9b81-4fb7-b9e3-2c28a663fe6b',
        status: 'active',
        type: 'Box',
        startedAt: '2024-09-13T12:54:14.000000Z',
        renewsAt: '2024-12-01T13:54:14.000000Z',
        terminatedAt: null,
        nextPossibleCancellationDate: null,
        products: [
          {
            id: '322923fd-8508-4232-8b11-09b1da1c2948',
            internalName: 'Fully Managed Standard',
            displayName: 'Setup',
            type: 'plan',
          },
          {
            id: '1157eb1c-0019-406c-bfa8-131a2ebd74b7',
            internalName: 'Fully Managed Standard',
            displayName: 'Fully Managed',
            type: 'product',
          },
        ],
        configuration: [],
        meta: {
          providerReference: 'BTcejgUOG5mOcJP3',
          reference: null,
          details: [
            {
              title: 'second starter',
              id: '19778',
            },
          ],
          customer: {
            id: 'b696111d-8f80-4457-9ea2-7b9fe011d0bd',
            internalCustomerId: '65001',
          },
        },
        payment: {
          interval: 3,
          type: 'month',
          method: 'bank_transfer',
          meta: {
            providerReference: null,
          },
        },
        currency: null,
      },
    ],
  },
  {
    reason: 'demoboxes',
    data: boxesData,
  },
  {
    reason: 'none',
  },
];

export default accountCancellationPossibleReasons;
