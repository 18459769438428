import { default as backupsLScZwuFgJrMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/backups.vue?macro=true";
import { default as configurationoVy6wqdP4aMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/configuration.vue?macro=true";
import { default as monitoringO281cgkrsZMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/monitoring.vue?macro=true";
import { default as overvieww4uCJr4XKbMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/overview.vue?macro=true";
import { default as plugins_45themesTezoA9aXPjMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/plugins-themes.vue?macro=true";
import { default as settingsayBIFaVy0JMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/settings.vue?macro=true";
import { default as _91id_93BKBd7R75oIMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/[id].vue?macro=true";
import { default as generalCOQn0Xj7nLMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/create-new/general.vue?macro=true";
import { default as select_45planB1WXFdOitqMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/create-new/select-plan.vue?macro=true";
import { default as summarySuZGgg5Oe4Meta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/create-new/summary.vue?macro=true";
import { default as wordpressispLasEttVMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/create-new/wordpress.vue?macro=true";
import { default as create_45new2qobdwwCUEMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/create-new.vue?macro=true";
import { default as indexPygbdVr7HeMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/index.vue?macro=true";
import { default as add_45onsyw4JhvfyQEMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/upgrade/[id]/add-ons.vue?macro=true";
import { default as payment_45optionsJ0dDRM21nUMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/upgrade/[id]/payment-options.vue?macro=true";
import { default as select_45plankcQJ7FwTbwMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/upgrade/[id]/select-plan.vue?macro=true";
import { default as summary7miUI4guLiMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/upgrade/[id]/summary.vue?macro=true";
import { default as _91id_93DCzMn7kmSBMeta } from "/builds/raidboxes/cbm/frontend/src/pages/boxes/upgrade/[id].vue?macro=true";
import { default as indexO4LIMviML9Meta } from "/builds/raidboxes/cbm/frontend/src/pages/index.vue?macro=true";
import { default as invoicesmnzmd0T2IUMeta } from "/builds/raidboxes/cbm/frontend/src/pages/invoices.vue?macro=true";
import { default as payment_45methodsel2tffuWbGMeta } from "/builds/raidboxes/cbm/frontend/src/pages/payment-methods.vue?macro=true";
import { default as _91id_93qAVVgTDNFZMeta } from "/builds/raidboxes/cbm/frontend/src/pages/subscriptions/[id].vue?macro=true";
import { default as indexXnM1n7yYX1Meta } from "/builds/raidboxes/cbm/frontend/src/pages/subscriptions/index.vue?macro=true";
import { default as subscriptions1YjaKsqw8YMeta } from "/builds/raidboxes/cbm/frontend/src/pages/subscriptions.vue?macro=true";
import { default as profileTNwi7PtN4vMeta } from "/builds/raidboxes/cbm/frontend/src/pages/user/profile.vue?macro=true";
import { default as securityLADLBeAhMsMeta } from "/builds/raidboxes/cbm/frontend/src/pages/user/security.vue?macro=true";
export default [
  {
    name: "boxes-id",
    path: "/boxes/:id()",
    meta: _91id_93BKBd7R75oIMeta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "boxes-id-backups",
    path: "backups",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/backups.vue").then(m => m.default || m)
  },
  {
    name: "boxes-id-configuration",
    path: "configuration",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/configuration.vue").then(m => m.default || m)
  },
  {
    name: "boxes-id-monitoring",
    path: "monitoring",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/monitoring.vue").then(m => m.default || m)
  },
  {
    name: "boxes-id-overview",
    path: "overview",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/overview.vue").then(m => m.default || m)
  },
  {
    name: "boxes-id-plugins-themes",
    path: "plugins-themes",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/plugins-themes.vue").then(m => m.default || m)
  },
  {
    name: "boxes-id-settings",
    path: "settings",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/[id]/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "boxes-create-new",
    path: "/boxes/create-new",
    meta: create_45new2qobdwwCUEMeta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/create-new.vue").then(m => m.default || m),
    children: [
  {
    name: "boxes-create-new-general",
    path: "general",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/create-new/general.vue").then(m => m.default || m)
  },
  {
    name: "boxes-create-new-select-plan",
    path: "select-plan",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/create-new/select-plan.vue").then(m => m.default || m)
  },
  {
    name: "boxes-create-new-summary",
    path: "summary",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/create-new/summary.vue").then(m => m.default || m)
  },
  {
    name: "boxes-create-new-wordpress",
    path: "wordpress",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/create-new/wordpress.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "boxes",
    path: "/boxes",
    meta: indexPygbdVr7HeMeta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/index.vue").then(m => m.default || m)
  },
  {
    name: "boxes-upgrade-id",
    path: "/boxes/upgrade/:id()",
    meta: _91id_93DCzMn7kmSBMeta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/upgrade/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "boxes-upgrade-id-add-ons",
    path: "add-ons",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/upgrade/[id]/add-ons.vue").then(m => m.default || m)
  },
  {
    name: "boxes-upgrade-id-payment-options",
    path: "payment-options",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/upgrade/[id]/payment-options.vue").then(m => m.default || m)
  },
  {
    name: "boxes-upgrade-id-select-plan",
    path: "select-plan",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/upgrade/[id]/select-plan.vue").then(m => m.default || m)
  },
  {
    name: "boxes-upgrade-id-summary",
    path: "summary",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/boxes/upgrade/[id]/summary.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexO4LIMviML9Meta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invoices",
    path: "/invoices",
    meta: invoicesmnzmd0T2IUMeta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/invoices.vue").then(m => m.default || m)
  },
  {
    name: "payment-methods",
    path: "/payment-methods",
    meta: payment_45methodsel2tffuWbGMeta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: subscriptions1YjaKsqw8YMeta?.name,
    path: "/subscriptions",
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/subscriptions.vue").then(m => m.default || m),
    children: [
  {
    name: "subscriptions-id",
    path: ":id()",
    meta: _91id_93qAVVgTDNFZMeta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/subscriptions/[id].vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "",
    meta: indexXnM1n7yYX1Meta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/subscriptions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "user-profile",
    path: "/user/profile",
    meta: profileTNwi7PtN4vMeta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-security",
    path: "/user/security",
    meta: securityLADLBeAhMsMeta || {},
    component: () => import("/builds/raidboxes/cbm/frontend/src/pages/user/security.vue").then(m => m.default || m)
  }
]