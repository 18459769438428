import revive_payload_client_4sVQNw7RlN from "/builds/raidboxes/cbm/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/raidboxes/cbm/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/raidboxes/cbm/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/builds/raidboxes/cbm/frontend/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/raidboxes/cbm/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/raidboxes/cbm/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/raidboxes/cbm/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/raidboxes/cbm/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/raidboxes/cbm/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/raidboxes/cbm/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/raidboxes/cbm/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/builds/raidboxes/cbm/frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_tbFNToZNim from "/builds/raidboxes/cbm/frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/builds/raidboxes/cbm/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_AUP22rrBAc from "/builds/raidboxes/cbm/frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import array_lEExQTLi8p from "/builds/raidboxes/cbm/frontend/src/plugins/array.ts";
import clickOutside_0ynIqyw7XJ from "/builds/raidboxes/cbm/frontend/src/plugins/clickOutside.ts";
import designsystem_ZZw60nng1n from "/builds/raidboxes/cbm/frontend/src/plugins/designsystem.ts";
import driverjs_naCoGRbT0R from "/builds/raidboxes/cbm/frontend/src/plugins/driverjs.ts";
import featureDirective_auS6Cf5JnP from "/builds/raidboxes/cbm/frontend/src/plugins/featureDirective.ts";
import i18n_YCWKwfVXF6 from "/builds/raidboxes/cbm/frontend/src/plugins/i18n.ts";
import sentry_client_KAXFuL2wum from "/builds/raidboxes/cbm/frontend/src/plugins/sentry.client.ts";
import validation_HY2e0OvVTz from "/builds/raidboxes/cbm/frontend/src/plugins/validation.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  plugin_tbFNToZNim,
  i18n_yfWm7jX06p,
  plugin_AUP22rrBAc,
  array_lEExQTLi8p,
  clickOutside_0ynIqyw7XJ,
  designsystem_ZZw60nng1n,
  driverjs_naCoGRbT0R,
  featureDirective_auS6Cf5JnP,
  i18n_YCWKwfVXF6,
  sentry_client_KAXFuL2wum,
  validation_HY2e0OvVTz
]