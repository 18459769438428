export const useToastStore = defineStore('toastStore', {
  state: (): ToastState => ({
    toasts: [],
  }),
  actions: {
    popToast(): RbToast | null {
      if (this.toasts.length > 0) {
        return this.toasts.pop() as RbToast;
      }
      return null;
    },
    addToast(toast: RbToast) {
      this.toasts.push(toast);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useToastStore, import.meta.hot));
}
